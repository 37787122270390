import * as URL from './url';
import axios from './axios';
// 获取banner
export async function getDistrict(data, options = {}) {
    return axios({
        url: URL.getDistrict,
        method: 'post',
        data,
        ...options,
    });
}
export async function doLogin(data, options = {}) {
    return axios({
        url: URL.doLogin,
        method: 'post',
        data,
        ...options,
    });
}
export async function getCurrent(data, options = {}) {
    return axios({
        url: URL.getCurrent,
        method: 'post',
        data,
        ...options,
    });
}
export async function getGroupList(data, options = {}) {
    return axios({
        url: URL.getGroupList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getGroupInfo(data, options = {}) {
    return axios({
        url: URL.getGroupInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editGroup(data, options = {}) {
    return axios({
        url: URL.editGroup,
        method: 'post',
        data,
        ...options,
    });
}
export async function getPermissionsList(data, options = {}) {
    return axios({
        url: URL.getPermissionsList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getAdminList(data, options = {}) {
    return axios({
        url: URL.getAdminList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getAdminInfo(data, options = {}) {
    return axios({
        url: URL.getAdminInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editAdmin(data, options = {}) {
    return axios({
        url: URL.editAdmin,
        method: 'post',
        data,
        ...options,
    });
}
export async function getBakList(data, options = {}) {
    return axios({
        url: URL.getBakList,
        method: 'post',
        data,
        ...options,
    });
}
export async function startBak(data, options = {}) {
    return axios({
        url: URL.startBak,
        method: 'post',
        data,
        ...options,
    });
}
export async function startDel(data, options = {}) {
    return axios({
        url: URL.startDel,
        method: 'post',
        data,
        ...options,
    });
}
export async function getBakWorksList(data, options = {}) {
    return axios({
        url: URL.getBakWorksList,
        method: 'post',
        data,
        ...options,
    });
}
export async function startBakWorks(data, options = {}) {
    return axios({
        url: URL.startBakWorks,
        method: 'post',
        data,
        ...options,
    });
}
export async function startBakWorksBySearch(data, options = {}) {
    return axios({
        url: URL.startBakWorksBySearch,
        method: 'post',
        data,
        ...options,
    });
}
export async function workListExcel(data, options = {}) {
    return axios({
        url: URL.workListExcel,
        method: 'post',
        data,
        ...options,
    });
}
export async function getUserList(data, options = {}) {
    return axios({
        url: URL.getUserList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getUserInfo(data, options = {}) {
    return axios({
        url: URL.getUserInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editUser(data, options = {}) {
    return axios({
        url: URL.editUser,
        method: 'post',
        data,
        ...options,
    });
}
export async function getArticleList(data, options = {}) {
    return axios({
        url: URL.getArticleList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getArticleInfo(data, options = {}) {
    return axios({
        url: URL.getArticleInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editArticle(data, options = {}) {
    return axios({
        url: URL.editArticle,
        method: 'post',
        data,
        ...options,
    });
}
export async function getConfigInfo(data, options = {}) {
    return axios({
        url: URL.getConfigInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editConfig(data, options = {}) {
    return axios({
        url: URL.editConfig,
        method: 'post',
        data,
        ...options,
    });
}
export async function getAdvList(data, options = {}) {
    return axios({
        url: URL.getAdvList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getAdvInfo(data, options = {}) {
    return axios({
        url: URL.getAdvInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editAdv(data, options = {}) {
    return axios({
        url: URL.editAdv,
        method: 'post',
        data,
        ...options,
    });
}
export async function editAdvBatch(data, options = {}) {
    return axios({
        url: URL.editAdvBatch,
        method: 'post',
        data,
        ...options,
    });
}
export async function getLinkList(data, options = {}) {
    return axios({
        url: URL.getLinkList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getLinkInfo(data, options = {}) {
    return axios({
        url: URL.getLinkInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editLink(data, options = {}) {
    return axios({
        url: URL.editLink,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorkRaceList(data, options = {}) {
    return axios({
        url: URL.getWorkRaceList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorkRaceInfo(data, options = {}) {
    return axios({
        url: URL.getWorkRaceInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editWorkRace(data, options = {}) {
    return axios({
        url: URL.editWorkRace,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorkRaceTopicList(data, options = {}) {
    return axios({
        url: URL.getWorkRaceTopicList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorkRaceTopicInfo(data, options = {}) {
    return axios({
        url: URL.getWorkRaceTopicInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editWorkRaceTopic(data, options = {}) {
    return axios({
        url: URL.editWorkRaceTopic,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorksList(data, options = {}) {
    return axios({
        url: URL.getWorksList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorksInfo(data, options = {}) {
    return axios({
        url: URL.getWorksInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorkAwardList(data, options = {}) {
    return axios({
        url: URL.getWorkAwardList,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorkAwardInfo(data, options = {}) {
    return axios({
        url: URL.getWorkAwardInfo,
        method: 'post',
        data,
        ...options,
    });
}
export async function editWorkAward(data, options = {}) {
    return axios({
        url: URL.editWorkAward,
        method: 'post',
        data,
        ...options,
    });
}
export async function getWorksDownload(data, options = {}) {
    return axios({
        url: URL.getWorksDownload,
        method: 'post',
        data,
        ...options,
    });
}
export async function getSearchFilter(data, options = {}) {
    return axios({
        url: URL.getSearchFilter,
        method: 'post',
        data,
        ...options,
    });
}
export async function getAnalysisMain(data, options = {}) {
    return axios({
        url: URL.getAnalysisMain,
        method: 'post',
        data,
        ...options,
    });
}


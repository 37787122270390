export const getDistrict = `/district/list`;
export const doLogin = `/admin/login`;
export const getCurrent = `/admin/current`;
export const getGroupList = `/admin/group/list`;
export const getGroupInfo = `/admin/group/info`;
export const editGroup = `/admin/group/edit`;
export const getPermissionsList = `/admin/permissions/list`;
export const getAdminList = `/admin/user/list`;
export const getAdminInfo = `/admin/user/info`;
export const editAdmin = `/admin/user/edit`;
export const getBakList = `/bak/list`;
export const startBak = `/bak/start`;
export const startDel = `/bak/del`;
export const getBakWorksList = `/bak/works/list`;
export const startBakWorks = `/bak/works/start`;
export const startBakWorksBySearch = `/bak/works/start/search`;
export const workListExcel = `/works/index/lists/excel`;
export const getUserList = `/user/list`;
export const getUserInfo = `/user/info`;
export const editUser = `/user/edit`;
export const getArticleList = `/article/list`;
export const getArticleInfo = `/article/info`;
export const editArticle = `/article/edit`;
export const getConfigInfo = `/config/info`;
export const editConfig = `/config/edit`;
export const getAdvList = `/adv/list`;
export const getAdvInfo = `/adv/info`;
export const editAdv = `/adv/edit`;
export const editAdvBatch = `/adv/edit/batch`;
export const getLinkList = `/link/list`;
export const getLinkInfo = `/link/info`;
export const editLink = `/link/edit`;
export const getWorkRaceList = `/works/race`;
export const getWorkRaceInfo = `/works/race/info`;
export const editWorkRace = `/works/race/edit`;
export const getWorkRaceTopicList = `/works/race/topic`;
export const getWorkRaceTopicInfo = `/works/race/topic/info`;
export const editWorkRaceTopic = `/works/race/topic/edit`;
export const getWorksList = `/works/index/lists`;
export const getWorksInfo = `/works/index/show`;
export const getWorkAwardList = `/works/award/lists`;
export const getWorkAwardInfo = `/works/award/info`;
export const editWorkAward = `/works/award/edit`;
export const getWorksDownload = `/works/download`;
export const getSearchFilter = `/works/search/filter`;
export const getAnalysisMain = `/analysis/count/main`;



import Axios from 'axios';
import httpStatus from './httpStatus';
import 'element-ui/lib/theme-chalk/index.css';
import { LoadingFn } from '@/plugins/loading';
import { Warning } from '@/plugins/warning';
import router from '@/router/index';
import store from '@/store/index';
import '@/plugins/element';
const pending = []; // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const cancelToken = Axios.CancelToken;
let requestNum = 0;
// const removePending = (ever) => {
    // for (const p in pending) {
    //   if (pending[p].u === `${ever.url}&${ever.method}`) {
    //     // 当前请求在数组中存在时执行函数体
    //     pending[p].f(); // 执行取消操作
    //     pending.splice(p, 1); // 把这条记录从数组中移除
    //   }
    // }
// };
const createAxiosFn = function (data) {
    if (!data.unLoading) {
        LoadingFn.open();
    }
    const requestData = data.data;
    const formData = new FormData();
    Object.keys(requestData).forEach((key)=>{
        formData.append(key,requestData[key])
    })
    data.data = formData
    return axiosInstance(data);
};
const axiosInstance = Axios.create({
    timeout: 60*60*1000,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
    baseURL: process.env.VUE_APP_SERVER_URL,
});
axiosInstance.interceptors.request.use(
    (config) => {
        ++requestNum;
        // removePending(config); // 在一个ajax发送前执行一下取消操作
        const token = store.getters["infoModule/getToken"];
        if (config.method === 'get') {
            //  给data赋值以绕过if判断
            config.data = true;
        }
        if (token) {
            config.headers.Authorization = token;
        }
        config.cancelToken = new cancelToken((c) => {
            // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
            pending.push({
                u: `${config.url}&${config.method}`,
                f: c,
            });
        });
        return config;
    },
    (error) => {
        if (
            Object.prototype.hasOwnProperty.call(error, 'config')
            && Object.prototype.hasOwnProperty.call(error, 'response')
        ) {
            Promise.reject(error);
        }
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        --requestNum;
        // removePending(response.config);
        const { data } = response;
        if (requestNum <= 0) {
            LoadingFn.close();
        }
        if (data) {
            if (data.status !== 200) {
                if (data.status === 410) {
                    //令牌失效需要重新登录
                    store.commit('infoModule/updateToken','')
                    router.push({name:'index'});
                    return Promise.reject(data);
                }else {
                    Warning.open(data.result.msg);
                    return Promise.reject(data);
                }
            } else {
                return Promise.resolve(data.result);
            }
        }
    },
    (error) => {
        --requestNum;
        if (requestNum <= 0) {
            LoadingFn.close();
        }
        LoadingFn.close();
        if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            if (error.response) {
                errorStatus(error.response.status);
                return Promise.reject(error.response.data);
            }else {
                Warning.open(error.message);
                return Promise.reject(error);
            }
        }
    },
);

function errorStatus(code) {
    Warning.open(`${code}: ${httpStatus[code] || ''}`);
}

export default createAxiosFn;
